<template>
  <NuxtLink :to="getSummonerPageLink(summoner)" class="summoner-search__result">
    <SummonerProfileIcon :summoner="summoner" size="4rem"/>

    <div class="summoner-search__result-name">
      {{ summoner.gameName }}<span>#{{ summoner.tagLine }}</span>
    </div>

    <div class="summoner-search__result-region">
      {{ summoner.region }}
    </div>

    <div v-if="rankedEntry" class="summoner-search__result-rank">
      {{ capitalizeString(rankedEntry.tier) }}
      {{ isHighElo(rankedEntry.tier) ? '' : rankedEntry.division }} -
      {{ rankedEntry.leaguePoints }}LP
      <TeamfightImage
          :alt="rankedEntry.tier || 'UNRANKED'"
          :src="getTFTRegaliaIconUrl(rankedEntry.tier || 'UNRANKED')"
          :style="{ height: '2rem', width: '2rem', transform: rankedEntry.tier ? 'scale(1.4, 1.4)' : 'none' }"
      />
    </div>
  </NuxtLink>
</template>

<script lang="ts" setup>
import { getTFTRegaliaIconUrl } from "~/utils/cdn";
import { capitalizeString } from "~/helpers/capitalizeString";
import { isHighElo } from "~/helpers/isHighElo";
import { patch } from "~/utils/patch";
import { RankedQueue } from "~/types/riot";
import { SummonerSearchResult } from "~/graphql/query/findSummoners/findSummoners";

interface Props {
  summoner: SummonerSearchResult;
}

const props = defineProps<Props>();

const rankedEntry = computed(() => props.summoner.rankedEntries.find(e => e.set === getSetFromPatch(patch(props.summoner.region).current.patchId).number && e.queueType === RankedQueue.RANKED_TFT));
</script>

<style lang="scss" scoped>
.summoner-search__result {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 0.4rem;
  transition: all 0.25s ease;
  border-radius: 0.3rem;

  &:hover {
    background-color: var(--UI-Accent-60);
  }

  .summoner-search__result-name {
    display: flex;
    align-items: center;
    gap: 0.4rem;

    span {
      font-size: 1.2rem;
      opacity: 0.6;
    }
  }

  .summoner-search__result-region {
    font-size: 1.2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.2rem 0.6rem;
    background-color: var(--UI-Secondary);
    border-radius: 0.3rem;
  }

  .summoner-search__result-rank {
    font-size: 1.2rem;
    margin-left: auto;
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-right: 1rem;
  }
}
</style>