<template>
  <div class="teamfight-loading" style="color: #F15D3B; transform: scale(0.9, 0.9)">
    <div/>
    <div/>
    <div/>
    <div/>
  </div>
</template>

<style lang="scss" scoped>
.teamfight-loading,
.teamfight-loading div {
  box-sizing: border-box;
}

.teamfight-loading {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.teamfight-loading div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid currentColor;
  border-radius: 50%;
  animation: teamfight-loading 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: currentColor transparent transparent transparent;
}

.teamfight-loading div:nth-child(1) {
  animation-delay: -0.45s;
}

.teamfight-loading div:nth-child(2) {
  animation-delay: -0.3s;
}

.teamfight-loading div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes teamfight-loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>